<template>
  <div class="info" onselectstart="javascript:return false;">
    <div class="profile">
      <img alt="" src="../../assets/personalCenter/个人默认头像.png" />
    </div>
    <div class="userInfo" v-loading="loading">
      <div class="btn-group__item">
        <span class="main-title">{{$t("messages.displayName")}}:</span>
        <br>
        <span class="main-input">{{displayName}}</span>
        <button class="main-button" @click="pushDisplayName">{{$t("messages.edit")}}</button>
      </div>
      <div class="btn-group__item">
        <span class="main-title">{{$t("messages.password")}}:</span>
        <br>
        <span class="main-input">******</span>
        <button class="main-button" @click="pushUpPassword">{{$t("messages.update")}}</button>
      </div>
      <div class="btn-group__item">
        <span class="main-title">{{$t("messages.userName")}}:</span>
        <br>
        <span class="main-input">{{userName}}</span>
      </div>
      <div class="btn-group__item">
        <span class="main-title">{{$t("messages.registTime")}}:</span>
        <br>
        <span class="main-input">{{createTime}}</span>
      </div>
    </div>
    <el-dialog :title="$t('messages.edit_displayName')" :visible.sync="displayNameVisible" width="680px"
      :before-close="handleClose" :modal="false">
      <div class="dialog-body">
        <span>{{$t('messages.displayName')}}：</span>
        <el-input size="middle" v-model.trim="display_name" maxlength="20" show-word-limit style="width: 85%;"
          @keyup.native="diykeyUp"></el-input>
        <p>{{$t('messages.powerTips_20')}}</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="submitDisplayNameUpdate"
          :disabled="!display_name">{{$t("messages.sure")}}</el-button>
        <el-button type="cancel" @click="cancelUpdateDisplayName" size="mini">{{$t("messages.cancel")}}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.editPassword')" :visible.sync="upPasswordVisible" width="680px"
      :before-close="handleClose" :modal="false">
      <div class="dialog-body">
        <span>{{$t('messages.userName')}}：{{userName}}</span>
        <el-form :model="passForm" status-icon :rules="rules" ref="passForm" class="demo-passForm">
          <el-form-item prop="code">
            <el-input size="middle" v-model="passForm.code" :placeholder="$t('messages.powerTips_21')">
              <span slot="suffix" class="input__inner" @click="getCode">{{codeStatus}}</span>
            </el-input>
          </el-form-item>
          <el-form-item prop="pass">
            <el-input type="password" v-model.trim="passForm.pass" autocomplete="off" show-password clearable
              @keypress.native.enter="onEnterPress('passForm')" :placeholder="$t('messages.newPassword')"
              @input="checkFont" maxlength="16" @keyup.native="keyUp" @keydown.native="keydown"
              @blur="checkPassStrong('passForm', passForm.pass, 1)"></el-input>
          </el-form-item>
          <el-form-item prop="rePass">
            <el-input type="password" v-model.trim="passForm.rePass" autocomplete="off" show-password clearable
              @keypress.native.enter="onEnterPress('passForm')" :placeholder="$t('messages.passwordAgain')"
              @input="checkFont2" maxlength="16" @keyup.native="keyUp" @keydown.native="keydown"
              @blur="checkPassStrong('passForm', passForm.rePass, 2)"></el-input>
          </el-form-item>
        </el-form>
        <span style="color: #666666;">{{$t('messages.passwordRule')}}：</span>
        <div>
          <el-checkbox v-model="fontLengthOK" disabled></el-checkbox>&nbsp;&nbsp;&nbsp;{{$t('messages.pass_tips_1')}}
        </div>
        <div>
          <el-checkbox v-model="fontFormatOK" disabled></el-checkbox>&nbsp;&nbsp;&nbsp;{{$t('messages.pass_tips_2')}}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" :disabled="!updateOK"
          @click="submitUpPassword('passForm')">{{$t("messages.sure")}}</el-button>
        <el-button type="cancel" @click="upPasswordVisible = false,resetForm('passForm')"
          size="mini">{{$t("messages.cancel")}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deepCopy, fontHide } from "@/utils/utils";
import { getUserDisplay, updateUserDisplay } from "@/api/api";
import { patchChangePassword, getTelCode } from "@/api/login/regist.js";
import { checkpwd_api } from "@/api/login/login.js";
import Md5 from "js-md5";
import { logout_api } from "@/api/login/login.js";
export default {
  data() {
    // 验证原密码
    let validatePass0 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("messages.enterPassword")));
      } else {
        if (this.passForm.oldPass !== "") {
          this.$refs.passForm.validateField("pass");
        }
        callback();
      }
    };
    // 新密码
    let validatePass1 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("messages.enterPassword")));
      } else {
        if (this.passForm.pass !== "") {
          this.$refs.passForm.validateField("rePass");
        }
        callback();
      }
    };
    // 验证新密码
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("messages.enterPassword2")));
      } else if (value !== this.passForm.pass) {
        callback(new Error(this.$t("messages.twoPasswordsInconsistent")));
      } else {
        callback();
      }
    };
    return {
      loading: true,
      displayNameVisible: false,
      upPasswordVisible: false,
      displayName: "none",
      userName: "****",
      createTime: "none",
      oldDisplayName: "none",
      display_name: "none",
      codeStatus: this.$t("messages.getVerificationCode"),
      updateOK: false,
      passForm: {
        oldPass: "",
        pass: "",
        rePass: "",
        code: "",
      },
      //   验证规则
      rules: {
        oldPass: [{ validator: validatePass0, trigger: "blur" }],
        pass: [{ validator: validatePass1, trigger: "blur" }],
        rePass: [{ validator: validatePass2, trigger: "blur" }],
      },
      codeDisable: { disabled: false, type: "success" },
      nums: 60,
      fontLengthOK: false,
      fontFormatOK: false,
      fontLengthOK2: false,
      fontFormatOK2: false,
      passStrong: false,
    };
  },
  methods: {
    handleClose(done) {
      this.resetForm("passForm");
      done();
    },
    //限制输入特殊字符
    diykeyUp(e) {
      e.target.value = e.target.value.replace(
        /[`!@#$%^*\=<>?:"{}|,\/;'\\\·！@#￥%……*（）——\={}|《》？：“”【】、；‘’，。、]/g,
        ""
      ); //NOSONAR
      e.target.value = e.target.value.replace(/\ +/g, "");
      e.target.value = e.target.value.replace(/[\r\n]/g, "");
    },
    keyUp(e) {
      e.target.value = e.target.value.replace(
        /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,
        ""
      ); //NOSONAR
      e.target.value = e.target.value.replace(/[^\x00-\xff]/g, ""); //NOSONAR
      e.target.value = e.target.value.replace(/\ +/g, "");
      e.target.value = e.target.value.replace(/[\r\n]/g, "");
    },
    keydown(e) {
      e.target.value = e.target.value.replace(/[^\a-\z\A-\Z0-9]/g, "");
    },
    getDisplay() {
      this.loading = true;
      getUserDisplay().then((res) => {
        if (res.code / 1000 === 200) {
          this.createTime = res.data.create_time;
          this.displayName = res.data.display_name;
          if (res.data.user_name) {
            this.userName = fontHide(res.data.user_name);
          } else {
            this.userName = "****";
          }
          this.loading = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    pushDisplayName() {
      this.oldDisplayName = deepCopy(this.displayName);
      this.display_name = deepCopy(this.displayName);
      this.displayNameVisible = true;
    },
    submitDisplayNameUpdate() {
      if (this.display_name.length > 0) {
        let params = {
          password: null,
          display_name: this.display_name,
        };
        updateUserDisplay(params).then((res) => {
          if (res.code / 1000 === 200) {
            this.displayName = this.display_name;
            this.displayNameVisible = false;
            localStorage.setItem("userName", this.display_name);
            this.$store.commit("setUserName", this.display_name);
            this.$message.success(this.$t("messages.operationSuccess"));
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    pushUpPassword() {
      this.upPasswordVisible = true;
    },
    onEnterPress(formName) {
      this.submitUpPassword(formName);
    },
    checkFont(val) {
      const reg = new RegExp("[A-Za-z0-9]+");
      this.fontFormatOK = true ? reg.test(val) : false;
      this.fontLengthOK = true ? val.length > 5 && val.length < 17 : false;
      this.updateOK =
        this.fontFormatOK &&
        this.fontFormatOK &&
        this.fontLengthOK2 &&
        this.fontFormatOK2; //NOSONAR
    },
    checkFont2(val) {
      const reg = new RegExp("[A-Za-z0-9]+");
      this.fontFormatOK2 = true ? reg.test(val) : false;
      this.fontLengthOK2 = true ? val.length > 5 && val.length < 17 : false;
      this.updateOK =
        this.fontFormatOK &&
        this.fontFormatOK &&
        this.fontLengthOK2 &&
        this.fontFormatOK2; //NOSONAR
    },
    submitUpPassword(formName) {
      if (!this.passStrong) return;
      this.$refs[formName].validate(async (valid) => {
        // 验证成功发送给接口
        if (valid) {
          if (this.passForm.pass !== this.passForm.rePass) {
            this.$message.error(this.$t("messages.twoPasswordsInconsistent"));
            return;
          }
          let res = await patchChangePassword({
            username: localStorage.getItem("user"),
            // oldPassword: Md5(this.passForm.oldPass),
            newPwd: Md5(this.passForm.pass),
            confirmPwd: Md5(this.passForm.rePass),
            code: this.passForm.code,
            type: 1,
            sign: "qcloud_pc_resetPwd_tel_getcode",
          });
          //  成功回调
          if (res.success) {
            this.$message.success(this.$t("messages.operationSuccess"));
            //  重置后返回登录
            this.resetForm(formName);
            this.upPasswordVisible = false;
            let params = {
              password: Md5(this.passForm.pass),
              display_name: null,
            };
            updateUserDisplay(params);
            logout_api().then((res) => {
              this.$router.push("/login");
            });
          } else {
            if (res.code == 10057) {
              this.$message.error(
                this.$t("messages.enterTrueVerificationCode")
              );
            } else if (res.code == 10072) {
              this.$message.error(this.$t("messages.accountDoesNotExist"));
            } else if (res.code == 10053) {
              this.$message.error(
                this.$t("messages.enterTrueVerificationCode")
              );
            } else {
              //  失败提示
              this.$message.error(res.message);
            }
          }
        } else {
          return false;
        }
      });
    },
    cancelUpdateDisplayName() {
      this.display_name = this.oldDisplayName;
      this.displayNameVisible = false;
    },
    resetForm(formName) {
      this.passForm = {
        oldPass: "",
        pass: "",
        rePass: "",
        code: "",
      };
      this.fontLengthOK = false;
      this.fontFormatOK = false;
      this.fontLengthOK2 = false;
      this.fontFormatOK2 = false;
    },
    getCode() {
      const params = {
        tel: localStorage.getItem("user"),
        type: "forgetPwd",
        sign: "qcloud_pc_resetPwd_tel_getcode",
      };
      if (!this.codeDisable.disabled) {
        getTelCode(params).then((res) => {
          if (res.success) {
            this.codeDisable.disabled = true;
            this.codeDisable.type = "info";
            this.setCode();
            this.$message.success(this.$t("messages.operationSuccess"));
          } else {
            if (res.code == 1) {
              this.$message.error(this.$t("messages.enterValidPhoneNumber"));
            } else {
              //  失败提示
              this.$message.error(res.message);
              // this.$message.error(this.$t('messages.accountDoesNotExist'));
            }
          }
        });
      }
    },
    setCode() {
      let _that = this;
      function doLoop() {
        if (_that.nums > 0) {
          _that.codeStatus = _that.nums + " s";
        } else {
          clearInterval(_that.clock);
          _that.nums = 60;
          _that.codeStatus = _that.$t("messages.getCode");
          _that.codeDisable.type = "success";
          _that.codeDisable.disabled = false;
        }
      }
      _that.clock = setInterval(function () {
        _that.nums--;
        doLoop();
      }, 1000);
    },
    async checkPassStrong(form, val, index) {
      let params = {
        password: val,
      };
      try {
        let res = await checkpwd_api(params);
        if (res.success) {
          this.passStrong = true;
        } else {
          this.passStrong = false;
          this.$refs[form].fields[index].validateMessage = res.message;
          this.$refs[form].fields[index].validateState = "error";
        }
      } catch (e) {}
    },
  },
  created() {
    this.getDisplay();
  },
};
</script>

<style lang="less" scoped>
.info {
  margin: 10px;
  background: #ffffff;
  border: 1px solid #dddddd;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  .profile {
    text-align: center;
    background: #fff;
    padding-top: 100px;
  }
  img {
    height: 100px;
    border-radius: 50%;
  }
  .userInfo {
    padding: 100px 400px;
  }
  .btn-group__item {
    position: relative;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 30px;
  }
  .main-title {
    font-size: 16px;
    font-weight: 700;
    color: #333333;
  }
  .main-input {
    height: 80px;
    line-height: 80px;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
  }
  .main-button {
    position: absolute;
    left: 80%;
    top: 0px;
    border: 1px solid #4385f2;
    border-radius: 20px;
    width: 115px;
    height: 40px;
    background: transparent;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    color: #4385f2;
  }
}
.dialog-body {
  margin: 30px 0px;
  p {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    float: right;
    margin-right: 20px;
    margin-top: 17px;
  }
  /deep/ .el-input__count .el-input__count-inner {
    background: transparent !important;
  }
  /deep/ .el-form-item__content {
    margin-bottom: 20px !important;
  }
  /deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
    border-color: #0c7de6;
    background: transparent;
  }
  /deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
    border-color: #0c7de6;
  }
}
/deep/ .el-dialog__footer {
  margin-top: 40px;
}
.el-button {
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  padding: 8px 26px;
}
.el-button--cancel {
  background: #b0b0b0;
}
.el-button--sure {
  background: #4385f2;
}
.input__inner {
  line-height: 40px;
  font-weight: 400;
  color: #0c7de6;
  cursor: pointer;
  padding-right: 10px;
}
.demo-passForm {
  margin-top: 20px;
}
</style>