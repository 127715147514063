import request from '@/api/login/request.js'
import {setCookie} from '@/utils/utils.js'
// import Cookies from 'js-cookie'

export function RandomNormal() {
  let RandomNormalStr = '';
  for (let i = 0; i < 6; i++) {
    RandomNormalStr += String(Math.floor(Math.random() * 10));
  }
  return RandomNormalStr;
}

export function RSA_SIGNATURE (tel, type) {
  const BZUJson = {
    rdm: '',
    cts: 0
  };
  const bzrCts = Date.now() + 20170911;
  const bzrCdm = RandomNormal();
  const encryptor = window.newJSEncrypt();

  const O1v = 'BEGIN PUBLIC KEY'
  const Ose = 'END PUBLIC KEY'
  const Oqs = [
    'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCP+hXVBiEeROxpsJSzeDFynMqO/cHG1Hw06q3zo+eC0apx+a5/RqLJK8X9cwJy12/OABO5Mu4gySYj0o2mzeOOjx1GL4wMokIAkGtQTzA3UnfF/twMMYVHQUPUBiOU8+zw1OqVmSJgZDqWqQZFmh9u9NcMDNrKnbYMYGMBkGeT0wIDAQAB'
  ]

  const other = `-----${O1v}-----${Oqs.join('')}-----${Ose}-----`;
  encryptor.setPublicKey(other);
  const bySign = encryptor.encrypt(`cts=${bzrCts}&rdm=${bzrCdm}&tel=${tel}&type=${type}`);
  BZUJson.rdm = bzrCdm;
  BZUJson.cts = bzrCts;
  // Cookies.set('originqsig', bySign || '');
  setCookie('originqsig', encodeURIComponent(bySign) || '');
  return BZUJson;
}

export function getTelCode(data) {
  const BUYData = RSA_SIGNATURE(data.tel, data.type);
  return request({
    url: '/uc/code/sendpcode.json',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
      ...BUYData,
    }
  })
}

export function registTel(data,headers) {
  return request({
    url: '/uc/core/user/registerSave.json',
    method: 'post',
    data,
    headers,
  })
}

export function getImageCode(data,headers) {
  return request({
    url: '/uc/code/imageCode.json',
    method: 'get',
    responseType: "blob",
    params: data,
    headers,
  })
}

export function patchChangePassword(data,headers) {
  return request({
    url: '/uc/userInfo/findPwd.json',
    method: 'post',
    data,
    headers,
  })
}

export function getEMailCode(data) {
  return request({
    url: '/uc/code/sendECode.json',
    method: 'post',
    data
  })
}

export function registEmail(data) {
  return request({
    url: '/uc/core/user/registerByEmail.json',
    method: 'post',
    data
  })
}

//ping
export function pingCookie() {
  return request({
    url: '/uc/userInfo/getUserInfo.json',
    method: 'get'
  })
}