import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import bus from '@/components/common/bus'
import store from '@/store'
import router from '@/router'

// create an axios instance
const service = axios.create({
  baseURL: '', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000, // request timeout
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    const token = store.state.token

    token && (config.headers.token = token)
    config.metadata = { startTime: new Date() }
    return config
  },
  error => {
    return Promise.error(error)
  }
)

// request interceptor
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    // do something with request error

    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    if (response.status === 200) {
      if (response.data.code === 400) {
        // Message.error(response.data.message)
        return res
      } else if (response.data.code === 401 || response.data.code === 500) {
        // Message.error(response.data.message)
        delCookie()
        bus.$emit('loginFailed', false)
        return res
      }
      return res
    } else {
      return res
    }
    // if the custom code is not 20000, it is judged as an error.
    // return res
  },
  error => {
    return error.response
  }
)

function delCookie() {
  let cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i].trim()
    let eqPos = cookie.indexOf('=')
    let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    if (name !== 'XSRF-TOKEN') {
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/'
    }
  }
  if (cookies.length > 0) {
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim()
      let eqPos = cookie.indexOf('=')
      let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
      if (name !== 'XSRF-TOKEN') {
        let domain = location.host.substr(location.host.indexOf('.'))
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=' + domain
      }
    }
  }
}

export default service
